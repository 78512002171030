import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFleetContext, useIndividualContext } from "../context";


function AdminList() {
	const navigate = useNavigate();
  const [drivers, setDrivers] = useState([]);
  const [partnerData, setPartnerData] = useState("");
  const [loading, setLoading] = useState(false);
  // const { dispatch } = useIndividualContext();
  const { dispatch: individualDispatch } = useIndividualContext(); 
    const { dispatch: fleetDispatch } = useFleetContext();
	// const { dipspatchFleet:dispatch } = useFleetContext();

	const getDriverFn = async () => {
    setLoading(true);
    try {
			const res = await axios.get(
				`${process.env.REACT_APP_BASE_URL}/getAllDrivers`
			);
			console.log(res);
      setDrivers(res.data);
      setLoading(false);
		} catch (error) {
      console.log(error);
      setLoading(false);
		}
	};

	const goToDriver = (ele) => {
    console.log("Driver data is ", ele);
    setPartnerData(ele);


    // let value = ele;
		
    
    if (ele.isfleet === 'true') {
      fleetDispatch({ type: "SET_PARTNER_DATA_FLEET", payload: ele }); // Dispatching to FleetContext
    	// navigate("/addfleet/fleetverifyadhaar");
    	navigate("/addfleet/dashboard");
    } else {
      individualDispatch({ type: "SET_PARTNER_DATA", payload: ele })
      // navigate("/addindividual/verifydl");
      navigate("/addindividual/verifyadhaar");
    }
    
	};

	useEffect(() => {
		getDriverFn();
	}, []);

	return (
    <div className="m-8 flex flex-col gap-2">
      {
        loading ?<h1 className="text-4xl text-yellow">Loading.....</h1>:<>{drivers &&
          drivers.map((ele, i) => (
            <div
              className="flex border gap-10 p-2 text-lg cursor-pointer"
              key={i}
              onClick={() => goToDriver(ele)}
            >
              <span>{i + 1}</span>
              <div>Name: {ele.isfleet === "true" ?ele.name:ele?.dl_number_data?.name}</div>
              <div>Contact: {ele.contact_number}</div>
              <div>Type : {ele.isfleet === "true" ? "Fleet" : "Individual"}</div>
            </div>
          ))}</>
      }
			
		</div>
	);
}

export default AdminList;
