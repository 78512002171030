import React from "react";

export const PrimaryButton = ({ text, handleOnclick }) => (
  <button
    className="bg-yellow p-2 w-full rounded-md mt-4"
    onClick={handleOnclick}
  >
    {text}
  </button>
);
