import React from 'react'
import Edit from './Edit'
export default function AdminDashboard() {


    return (
        <>
        <Edit/>
        </>
    )
}
