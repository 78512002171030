import React, { useState } from 'react'
import ErrorImg from '../assets/error_icon.svg'
import Modal from 'react-modal'
import crossSvg from "../assets/Vector (1) copy.svg";
export default function Error({ errorMessage, setErrorMessage }) {
    const customStyles = {
        content: {
          inset: "50% -100px -100px 50%",
          padding: "20px",
          transform: "translate(-50%, -50%)",
          height: "50%",
          maxWidth: "400px",
        },
        overlay: {
          background: "rgb(0 0 0 / 70%)",
          zIndex: "3",
        },
      };
    return (
        <>
            <Modal isOpen={errorMessage == "" ? false : true } 
            style={customStyles}
            >
                <div className="flex justify-between">
                    <div className=""></div>
                 <img
              className=" cursor-pointer"
              onClick={() => {
                  setErrorMessage("");
                }}
                src={crossSvg}
                alt=""
                />
                </div>
                <div className=" flex flex-col items-center">
                    <div className="h-[half] pb-8">
                        <img src={ErrorImg} alt="" />
                    </div>
                    <div className="text-black font-bold py-4 text-center">Invalid Information or Network Error</div>
                    {
                        errorMessage ?
                            <div className=" font-semibold text-red-600 text-center">
                                {errorMessage}
                            </div>
                            :
                            <div className=" font-semibold text-red-600 text-center">
                                An Error Occured
                            </div>
                    }
                </div>
            </Modal>
        </>
    )
}