import { createContext, useReducer, useContext } from 'react'

export const FleetContext = createContext()
const initialState = {
  selected_location: "",
  contact_number: "",
  name: "",
  email: "",
  gender: "",
  dlnumber: "",
  dob: "",
  dl_front_img: "",
  dl_back_img: "",
  dl_number_checked: false,
  dl_number_data: "",
  partner_photo: "",
  pan_no: "",
  pan_img: "",
  aadhaar_no: "",
  aadhaar_front_img: "",
  aadhaar_back_img: "",
  aadhar_data: "",
  aadhar_checked: false,
  aadhar_skipped:false,
  car_available: true,
  vehicle_number: "",
  num_plate_img: "",
  carrier_available: false,
  car_data: "",
  is_rc_verified: "",
  is_agreed: false,
  bank_details: {
    full_name: "",
    bank_name: "",
    account_type: "",
    account_no: "",
    branch_name: "",
    ifsc: "",
  },
  gst_number: "",
}
export const FleetReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }
    case "SET_TO_INITIAL":
      return { ...initialState }
    case "SET_PARTNER_DATA_FLEET":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state
  }
}
export const useFleetContext = () => {
  const context = useContext(FleetContext)

  if (!context) {
    throw Error('useFleetContext must be used inside an FleetContextProvider')
  }

  return context
}
export const FleetContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(FleetReducer, initialState)
  return (
    <FleetContext.Provider value={{ ...state, dispatch }}>
      {children}
    </FleetContext.Provider>
  )
}