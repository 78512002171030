import { Routes, Route, Navigate } from "react-router-dom";

import loadingGif from "./assets/carLoading.gif";
import "./App.css";

import Onboard from "./pages/Onboard";
import Landing from "./pages/Landing";
import AddDriver from "./pages/AddDriver";
import AddVehicle from "./pages/AddVehicle";
import RegisterOption from "./pages/Register";
import AddIndividual from "./pages/addIndividual/AddIndividual";
import VerifyDL from "./pages/addIndividual/VerifyDL";
import VerifyAdhaar from "./pages/addIndividual/VerifyAdhaar";
import FleetVerifyAdhaar from "./pages/addFleet/FleetVerifyAdhaar";
import AddFleet from "./pages/addFleet/AddFleet";
import FleetDashboard from "./pages/addFleet/FleetDashboard";
import VerifyRC from "./pages/addIndividual/VerifyRC";
import AddFleetDriver from "./pages/addFleet/AddFleetDriver";
import FleetAddCar from "./pages/addFleet/AddFleetCar";
import Success from "./pages/addIndividual/Success";
import AddBankDetails from "./pages/addIndividual/AddBankDetails";

import { useEffect, useState } from "react";

import {
  useLocationContext,
  useFleetContext,
  useIndividualContext,
} from "./context";

import AddFleetBankDetails from "./pages/addFleet/AddFleetBankDetails";
import Error from "./components/Error";
import AdminDashboard from "./pages/AdminDashboard";
import Modal from "react-modal";
import { motion } from "framer-motion";
import AdminList from "./pages/AdminList";
import AdminLogin from "./pages/AdminLogin";
import { getRequest } from "./utils/api";

function App() {
  const [pwamodalstate, setPwaModalState] = useState(true);
  const {
    contact_number: individual_contact_number,
    dl_number_checked: individual_dl_checked,
    aadhar_checked: individual_aadhar_checked,
    selected_location: individual_selected_location,
    aadhar_skipped: individual_aadhar_skiped,
  } = useIndividualContext();
  const { location, setLocation } = useLocationContext();
  const {
    aadhar_checked: fleet_aadhaar_checked,
    aadhar_skipped: fleet_aadhar_skipped,
    contact_number: fleet_contact_number,
  } = useFleetContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    const getLocations = async () => {
      setLoading(true);
      try {
        const response = await getRequest("getAllStations", {
          signal: abortController.signal,
        });
        setLocation(response.data);
        setLoading(false);
      } catch (error) {
        if (error.name !== "AbortError") {
          alert("Could not fetch locations. Some error occurred");
          console.log(error);
          setLoading(false);
        }
      }
    };

    // Only call getLocations if location is empty
    if (location.length === 0) {
      getLocations();
    }

    return () => {
      abortController.abort();
    };
  }, [location.length]); // Only depend on location.length

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isAdminLogin, setIsAdminLogin] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const checkIndividual = individual_aadhar_checked || individual_aadhar_skiped;
  // console.log("individual_aadhar_checked value is ", individual_aadhar_checked);
  // console.log("individual_aadhar_skiped value is ", individual_aadhar_skiped);
  // console.log("checkIndividual value is ", checkIndividual);

  const checkFleet = fleet_aadhaar_checked || fleet_aadhar_skipped;
  // console.log("fleet_aadhaar_checked value is ", fleet_aadhaar_checked);
  // console.log("fleet_aadhar_skipped value is ", fleet_aadhar_skipped);
  // console.log("checkFleet value is ", checkFleet);

  const customStyles = {
    content: {
      inset: "50% -100px -100px 50%",
      padding: "1rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      transform: "translate(-50%, 350%)",
      height: "10%",
      border: "0px",
      overflowY: "hidden",
      width: "fit-content",
      backgroundColor: "transparent",
      // maxWidth: "400px",
      // backgroundColor : "black"
    },
    overlay: {
      background: "rgb(0 0 0 / 70%)",
      zIndex: "3",
    },
  };

  // console.log(individual_contact_number);
  return (
    <>
      {/* pwa modal */}
      <Modal isOpen={pwamodalstate} style={customStyles}>
        <motion.div
          className="bg-transparent"
          animate={{
            scale: 1,
            // rotate: [0, 0, 270, 270, 0],
            // borderRadius: ["20%", "20%", "50%", "50%", "20%"],
          }}
          initial={{
            scale: 0,
          }}
          transition={{
            duration: 0.33,
          }}
        >
          <div className="p-4 z-[3] w-full bg-white rounded-md flex justify-around">
            <button
              type="button"
              onClick={() => deferredPrompt && deferredPrompt.prompt()}
              className="mr-4 hover:text-[#ffc700] hover:bg-black hover:border hover:border-black p-2 px-2 bg-[#ffc700] rounded-md"
            >
              Add To Home Screen
            </button>
            <button
              type="button"
              onClick={() => setPwaModalState(false)}
              className="hover:text-red-800 hover:bg-white hover:border hover:border-black rounded-md text-white hover:font-bold p-2 px-2 border border-red-800  bg-red-800"
            >
              Close
            </button>
          </div>
        </motion.div>
      </Modal>
      {/* Protected Routes */}
      {!loading ? (
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/onboard" element={<Onboard />} />
          <Route path="/driverkyc" element={<AddDriver />} />
          <Route
            path="/addindividual"
            element={
              individual_selected_location ? (
                <AddIndividual />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/addindividual/verifydl"
            element={
              individual_contact_number ? (
                <VerifyDL />
              ) : (
                <Navigate to="/addindividual" />
              )
            }
          />
          <Route
            path="/addindividual/verifyadhaar"
            element={
              individual_dl_checked ? (
                <VerifyAdhaar />
              ) : (
                <Navigate to="/addindividual/verifydl" />
              )
            }
          />
          <Route
            path="/addindividual/verifyrc"
            element={
              individual_aadhar_checked || individual_aadhar_skiped ? (
                <VerifyRC />
              ) : (
                <Navigate to="/addindividual/verifyadhaar" />
              )
            }
          />
          {/* <Route path='/addindividual/verifyrc' element={<VerifyRC/>}/> */}

          <Route
            path="/addindividual/addbankdetails"
            element={
              individual_aadhar_checked || individual_aadhar_skiped ? (
                <AddBankDetails />
              ) : (
                <Navigate to="/addindividual/verifyadhaar" />
              )
            }
          />

          <Route path="/error" element={<Error />} />

          <Route path="/addfleet" element={<AddFleet />} />
          {/* <Route
						path="/addfleet/fleetverifyadhaar"
						element={
							true ? (
								<FleetVerifyAdhaar />
							) : (
								<Navigate to="/addfleet" />
							)
						}
					/> */}
          <Route
            path="/addfleet/fleetverifyadhaar"
            element={
              fleet_contact_number ? (
                <FleetVerifyAdhaar />
              ) : (
                <Navigate to="/addfleet" />
              )
            }
          />
          {/* <Route path='/addfleet/fleetverifyadhaar' element={<FleetVerifyAdhaar/>}/> */}
          {/* <Route
						path="/addfleet/addfleetdriver"
						element={
							true ? (
								<AddFleetDriver />
							) : (
								<Navigate to="/addfleet/fleetverifyadhaar" />
							)
						}
					/> */}
          <Route
            path="/addfleet/addfleetdriver"
            element={
              fleet_aadhaar_checked || fleet_aadhar_skipped ? (
                <AddFleetDriver />
              ) : (
                <Navigate to="/addfleet/fleetverifyadhaar" />
              )
            }
          />
          {/* <Route
						path="/addfleet/dashboard"
						element={
							true ? (
								<FleetDashboard />
							) : (
								<Navigate to="/addfleet/fleetverifyadhaar" />
							)
						}
					/> */}
          <Route
            path="/addfleet/dashboard"
            element={
              fleet_aadhaar_checked || fleet_aadhar_skipped ? (
                <FleetDashboard />
              ) : (
                <Navigate to="/addfleet/fleetverifyadhaar" />
              )
            }
          />
          {/* <Route path='/addfleet/dashboard' element={<FleetDashboard/>}/> */}
          {/* <Route path='/addfleet/complete' element={<Registered/>}/> */}
          {/* <Route
						path="/addfleet/addfleetcar"
						element={
							true ? (
								<FleetAddCar />
							) : (
								<Navigate to="/addfleet/fleetverifyadhaar" />
							)
						}
					/> */}
          {/* <Route
						path="/addfleet/addbankdetails"
						element={
							true ? (
								<AddFleetBankDetails />
							) : (
								<Navigate to="/addfleet/fleetverifyadhaar" />
							)
						}
					/> */}
          <Route
            path="/addfleet/addfleetcar"
            element={
              fleet_aadhaar_checked || fleet_aadhar_skipped ? (
                <FleetAddCar />
              ) : (
                <Navigate to="/addfleet/fleetverifyadhaar" />
              )
            }
          />
          <Route
            path="/addfleet/addbankdetails"
            element={
              fleet_aadhaar_checked || fleet_aadhar_skipped ? (
                <AddFleetBankDetails />
              ) : (
                <Navigate to="/addfleet/fleetverifyadhaar" />
              )
            }
          />
          {/* <Route path='/addfleet/addfleetcar' element={<FleetAddCar/>}/> */}

          <Route path="/vehiclekyc" element={<AddVehicle />} />
          <Route path="/registeroption" element={<RegisterOption />} />
          <Route path="/addindividual/complete" element={<Success />} />

          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/adminLogin" element={<AdminLogin />} />
          <Route path="/adminLogin/dashboard" element={<AdminList />} />

          {/* <Route path='/dashboard' element={<VehicleDashboard/>}/> */}
          {/* <Route path='/login' element={<Login/>}/> */}
        </Routes>
      ) : (
        <div className="w-screen h-screen bg-gray-100 overflow-scroll">
          {/* shows the loading gif while station data is being fetched */}
          <div className="max-w-[1000px] bg-white w-full m-auto min-h-screen p-5 grid place-items-center">
            <div className=" w-[250px] mx-auto">
              <img
                className="w-[165px] h-[130px] mx-auto"
                src={loadingGif}
                alt="loading..."
              />
              <div className=" whitespace-normal text-xs text-gray-500 text-center">
                {loading}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
