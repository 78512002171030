import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Skeleton } from "@mui/material";

import { useLocationContext } from "../context";

export const StationsList = () => {
  const { location, setSelectedLocation } = useLocationContext();

  if (location.length === 0) {
    return <Skeleton />;
  }

  return (
    location.length > 0 && (
      <Autocomplete
        sx={{ my: 2 }}
        id="station-list"
        options={location.map((station) => station.name)}
        autoHighlight
        renderInput={(params) => (
          <TextField {...params} label="Select Your Location" />
        )}
        onChange={(_e, newValue) => setSelectedLocation(newValue)}
      />
    )
  );
};
