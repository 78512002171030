import imageCompression from "browser-image-compression";

export const compressImage = async (image) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1200,
  }
  let compressedFile;
  try {
    compressedFile = await imageCompression(image, options);
  } catch (error) {
    console.log('Error occured while compressing image', error);
  }

  return compressedFile;
}