import axios from "axios";

import { showErrorNotification } from "../components";

export const sendMobileOTP = async (contact_number, isfleet, isfleetdriver) => {
  if (contact_number.length < 10) {
    showErrorNotification("Invalid mobile number");
    return;
  }
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/sendPartnerOTP`,
      {
        contact_number,
        isfleet,
        isfleetdriver,
      }
    );

    return response.data;
  } catch (e) {
    if (e.response?.status === 400) {
      showErrorNotification(
        "This number is already registered in fleet section"
      );
    } else {
      showErrorNotification(
        "Error occured while sending otp. Please try again"
      );
    }
  }
};

export const verifyMobileOTP = async (otp, phoneNumber, DataBaseKey) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/verifyPhoneNumber`,
      {
        otp,
        phoneNumber,
        DataBaseKey,
      }
    );

    return response.data.message === "OTP is correct";;
  } catch (error) {
    showErrorNotification("Error occured. Please try again");
    return false;
  }
};
