import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageInput from "../../components/ImageInput";
import arrowLeft from "../../assets/ArrowLeft.svg";
import axios from "axios";
import loadingGif from "../../assets/carLoading.gif";
import { useLocationContext, useFleetContext } from "../../context";
import Error from "../../components/Error";
import { motion, AnimatePresence } from "framer-motion";
import arrowDown from "../../assets/arrowdown-svgrepo-com.svg";
import { uploadFile } from "../../utils/fileUpload/imageUpload";
import { seats_array } from "../../utils/constants";

function FleetAddCar() {
  const { contact_number } = useFleetContext();
  const [numplateimg, setNumPlateImg] = useState("");
  const [rcverified, setRcVerified] = useState("");
  const [seats, setSeats] = useState("");
  const [vehiclenumber, setVehicleNumber] = useState("");
  const [cardata, setCarData] = useState("");
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  let base_url = process.env.REACT_APP_BASE_URL;
  const [currentPage, setCurrentPage] = useState(4);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const { selectedLocation } = useLocationContext();
  // const uploadImage = async () => {

  //     let formData = new FormData();

  //     formData.append("file", numplateimg);
  //     console.log(formData);
  //     try {
  //         const response = await fetch(`https://us-central1-app-gadigoda.cloudfunctions.net/uploadFile`, {
  //             method: "POST",
  //             body: formData,
  //         });
  //         console.log(response);
  //     }
  //     catch (error) {
  //         setError("some error occured");
  //         console.log(error);
  //     }
  // }
  const verifyRC = async () => {
    const url = `https://kyc-api.surepass.io/api/v1/rc/rc-lite-v2`;
    setLoading("Verifying RC plate number");
    console.log(vehiclenumber);
    try {
      const response = await axios.post(
        url,
        { id_number: vehiclenumber },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SUREPASS_TOKEN}`,
          },
        }
      );
      if (response.data.success === true) {
        setRcVerified(true);
        setCarData(response.data.data);
        // setLoading(false);
        console.log(response);
        addCar(response.data.data);
      } else {
        // setLoading(false);
        console.log("error", response);
        setError("Invalid RC");
      }
    } catch (e) {
      // setLoading(false);
      console.log(e);
      setError("Some Error Occured");
    }
  };
  async function addCar(rc_data) {
    let car_info = {
      contact_number: contact_number,
      id: vehiclenumber + "_" + contact_number,
      vehicle_location: selectedLocation,
      vehicle_number: vehiclenumber,
      is_fleet_car: "true",
      car_data: rc_data,
      num_plate_img: numplateimg,
      seats: seats,
    };
    if (
      typeof car_info.num_plate_img !== "string" ||
      !car_info.num_plate_img.includes(
        "https://storage.cloud.google.com/app-gadigoda.appspot.com"
      )
    ) {
      let response = await uploadFile(car_info.num_plate_img);
      console.log(response);
      if (response !== "failed") {
        car_info.num_plate_img = response;
        setNumPlateImg(response);
      } else {
        console.log(response);
        setError("Image upload failed. Please try again");
      }
    } else {
      console.log(car_info.num_plate_img);
    }
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/addCar`,
        car_info
      );
      console.log(response);
      setLoading(false);
      navigate("/addfleet/dashboard");
    } catch (e) {
      setError("Some Error Occured");
      setLoading(false);
      console.log(e);
    }
  }

  const previewFile = (file) => {
    return new Promise(function (resolve) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        return resolve(reader.result);
      };
    });
  };
  return (
    <>
      <Error errorMessage={error} setErrorMessage={setError} />
      <div className="w-screen h-screen bg-gray-100 overflow-scroll">
        <div className="max-w-[1000px] bg-white w-full m-auto min-h-screen p-5">
          <div className="flex items-center gap-2">
            <img
              src={arrowLeft}
              alt=""
              onClick={() => {
                navigate("/addfleet/dashboard");
              }}
            />
            <div className=" text-base font-semibold">Adding a new Car</div>
          </div>

          {loading === false ? (
            <div className="mt-8">
              {/* if page is 2 then show driver license verification form/page */}
              {currentPage === 4 && (
                <div>
                  {/* <div className=' w-full max-w-[500px] h-[2px] my-4 bg-gray-300 '></div> */}
                  {
                    <>
                      <div className="h-[78vh]">
                        <div className=" text-xs mb-1">
                          License Plate Number
                          <span className=" text-[#DC3545]">*</span>
                        </div>
                        <input
                          type="text"
                          placeholder="Eg: MH091823"
                          value={vehiclenumber}
                          className=" border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
                          onChange={(e) => {
                            setVehicleNumber(e.target.value);
                            setRcVerified(false);
                          }}
                        />
                        <div className="relative">
                          <div
                            className="relative z-[2] p-3 py-3 bg-gray-100 w-full max-w-[500px] flex justify-between mt-2 rounded-md cursor-pointer"
                            onClick={() => {
                              setIsDropDownOpen(!isDropDownOpen);
                            }}
                          >
                            <div>{!seats ? "Select seat count" : seats}</div>
                            <motion.img
                              animate={{
                                rotate: isDropDownOpen ? 180 : 0,
                              }}
                              src={arrowDown}
                              className="w-6 h-6"
                              alt=""
                            />
                          </div>

                          <AnimatePresence>
                            {isDropDownOpen && (
                              <motion.div
                                initial={{
                                  y: "-8px",
                                  opacity: 0,
                                }}
                                animate={{
                                  opacity: 1,
                                  y: "0px",
                                }}
                                exit={{
                                  y: "-8px",
                                  opacity: 0,
                                }}
                                className="absolute bg-white z-[1] max-h-[200px] overflow-scroll mt-3 rounded-md shadow-[0px_0px_5px_0px_rgba(0,0,0,0.3)] w-full max-w-[500px]"
                              >
                                {seats_array?.map((seat) => {
                                  return (
                                    <div
                                      className="p-3 cursor-pointer hover:bg-gray-100"
                                      onClick={() => {
                                        setSeats(seat);
                                        setRcVerified(false);
                                        // dispatch({
                                        //   type: "SET_DATA",
                                        //   payload: {
                                        //     name: "selected_location",
                                        //     value: station?.name,
                                        //   },
                                        // });
                                        setIsDropDownOpen(false);
                                      }}
                                      key={seat}
                                    >
                                      {seat}
                                    </div>
                                  );
                                })}
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </div>
                        <ImageInput
                          handleChange={(e) => {
                            let file = e.target.files[0];
                            // let uploadedImage = URL.createObjectURL(file);
                            // console.log(uploadedImage);
                            setNumPlateImg(file);
                            // if (file) {
                            //     previewFile(file).then((value) => {
                            //         dispatch({type: "SET_DATA", payload: {name: "num_plate_img", value: value}})

                            //     })
                            // }
                          }}
                          id="plate_img"
                          image={numplateimg}
                          labelText="Upload License Plate Image"
                          imgText="license_plate"
                          handleDel={() => {
                            setNumPlateImg("");
                          }}
                        />
                        <span className="text-[10px] text-yellow">
                          Image size sholud be less than 1mb
                        </span>
                        {/* <div className="py-8">
                                        <p>Select Location for this vehicle</p>
                                        <div className="py4 ">
                                            {
                                                <div className="shadow-[rgba(99,99,99,0.2)_0px_2px_8px_0px] mt-4 overflow-scroll max-h-[40vh] border-[1px] rounded-md border-[#dedede] w-full max-w-[500px]">
                                                    {
                                                        location?.map((i) => {
                                                            return (
                                                                <div key={i.name} className='flex justify-between px-4 py-2'>
                                                                    <label for={i.name}>{i.name}</label>
                                                                    <input className='scale-[1.4] accent-black' type="radio" name="station" id={i.name} value={i.name} onChange={(e) => { setselectedLocation(e.target.value) }} />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div> */}
                      </div>
                      {/* <div className='flex items-center mt-4'>
                                    <input
                                        className='w-4 h-4'
                                        type="checkbox"
                                        checked={carrier_available}
                                        id='carrier_available'
                                        onChange={(e) => dispatch({type: "SET_DATA", payload: {name: "carrier_available", value: e.target.checked}})}
                                    />
                                    <label htmlFor='carrier_available' className='pl-2 text-sm'>
                                        Do your car have Carrier
                                    </label>
                                </div> */}
                      {
                        <button
                          className="mt-5 border-2 py-2 w-full max-w-[500px] rounded-md border-black"
                          onClick={() => {
                            // uploadImage();
                            verifyRC();
                          }}
                        >
                          Verify RC and Add Car
                        </button>
                      }
                    </>
                  }
                </div>
              )}
            </div>
          ) : (
            <div className=" w-[250px] mx-auto mt-20">
              {/* if loading then show loading gif */}
              <img
                className="w-[165px] h-[130px] mx-auto"
                src={loadingGif}
                alt=""
              />
              <div className=" whitespace-normal text-xs text-gray-500 text-center">
                {loading}
              </div>
            </div>
          )}
          {/* {(loading === false && rcverified) && <div className='fixed w-full max-w-[1000px] bottom-0 text-right left-1/2 -translate-x-1/2 p-4 pr-6 mb-2'>
                        <button className=' bg-black w-full text-white rounded-md px-8 py-2'>Add Car</button>
                    </div>} */}
        </div>
      </div>
    </>
  );
}

export default FleetAddCar;
