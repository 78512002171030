import axios from "axios";

import { showErrorNotification, showSuccessNotification } from "../components";

export const addDriver = async (contact_number, isfleet, isfleetdriver) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/addDriver`,
      {
        contact_number,
        id: contact_number,
        isfleet,
        isfleetdriver,
      }
    );
    showSuccessNotification("Driver Data added");
    return response;
  } catch (e) {
    showErrorNotification("Error Occured. Please try again");
  }
};
