import axios from "axios";
import React, { useState } from "react";

function EditableTextFields() {
	const initialAllDrivers = [
		{
			id: 1,
			fields: [
				{ id: 1, name: "Field 1", text: "Field 1 text" },
				{ id: 2, name: "Field 2", text: "Field 2 text" },
				{ id: 3, name: "Field 3", text: "Field 3 text" },
			],
			isEditing: false,
		},
		{
			id: 2,
			fields: [
				{ id: 4, name: "Set 2 Field 1", text: "Set 2 Field 1 text" },
				{ id: 5, name: "Set 2 Field 2", text: "Set 2 Field 2 text" },
				{ id: 6, name: "Set 2 Field 3", text: "Set 2 Field 3 text" },
			],
			isEditing: false,
		},
	];

	const [alldrivers, setAllDrivers] = useState(initialAllDrivers);

	const handleSaveClick = (setIndex) => {
		// Extract the set data for the specified setIndex
		const setToSave = alldrivers[setIndex];

		// Log or send the whole data of the set as needed
		console.log("Set Data to Save:", setToSave);

		// ... Additional logic to send data as needed ...
	};

	const handleEditClick = (setIndex) => {
		setAllDrivers((prevAllDrivers) =>
			prevAllDrivers.map((set, index) =>
				index === setIndex
					? {
							...set,
							isEditing: true,
							fields: set.fields.map((field) => ({
								...field,
								prevText: field.text, // Store previous text
							})),
					  }
					: { ...set, isEditing: false }
			)
		);
	};

	const handleCancelClick = (setIndex) => {
		setAllDrivers((prevAllDrivers) =>
			prevAllDrivers.map((set, index) =>
				index === setIndex
					? {
							...set,
							isEditing: false,
							fields: set.fields.map((field) => ({
								...field,
								tempText: undefined,
								text: field.prevText || field.text, // Revert to previous text
								prevText: undefined, // Clear previous text
							})),
					  }
					: set
			)
		);
	};

	const handleChange = (setIndex, fieldId, value) => {
		setAllDrivers((prevAllDrivers) =>
			prevAllDrivers.map((set, index) =>
				index === setIndex
					? {
							...set,
							fields: set.fields.map((field) =>
								field.id === fieldId ? { ...field, tempText: value } : field
							),
					  }
					: set
			)
		);
	};

	const getDriverFn = async () => {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_BASE_URL}/getAllDrivers`
			);
			console.log(res);
		} catch (error) {
			console.log(error);
		}
	};

	const sendSms = async () => {
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/confirmationSMS`,
				{ contact_number: 9892584732,id:9892584732 }
			);
			console.log(res);
		} catch (error) {
      console.log(error);
		}
	};
	return (
		<div>
			{alldrivers.map((set, setIndex) => (
				<div key={set.id}>
					<button onClick={() => handleEditClick(setIndex)}>Edit</button>
					{set.fields.map((field) => (
						<div key={field.id}>
							{set.isEditing ? (
								<div>
									<input
										type="text"
										value={field.tempText || field.text}
										onChange={(e) =>
											handleChange(setIndex, field.id, e.target.value)
										}
									/>
								</div>
							) : (
								<div>
									<p>{field.text}</p>
								</div>
							)}
						</div>
					))}
					{set.isEditing && (
						<div className="flex gap-5">
							<button onClick={() => handleSaveClick(setIndex)}>Save</button>
							<button onClick={() => handleCancelClick(setIndex)}>
								Cancel
							</button>
						</div>
					)}
				</div>
			))}

			<button className="text-black border-2 px-4 py-1" onClick={getDriverFn}>
				Click BTN
			</button>
			<button className="text-black border-2 px-4 py-1" onClick={sendSms}>
				SEND BTN
			</button>
		</div>
	);
}

export default EditableTextFields;
