import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

	const submitForm = async(e) => {
    e.preventDefault();
    console.log(email, password);
    // setLoading(true);
    // try {
    //   const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/adminLogin`, { email, password });
    //   console.log(res);
    //   setLoading(false);
      
    //   if (res.message == "Logged In") {
    //     navigate('/adminLogin/dashboard')
    //   } else {
    //     setErr(true);
    //   }
      
    // } catch (error) {
    //   console.log(error);
    //   setLoading(false);
    // }

    if ((email === "app@gadigoda.com"  && password === "1234") || (email==="newapp@gadigoda.com"&& password==="8426")) {
      navigate('/adminLogin/dashboard')
    } else {
      setErr(true);
      setTimeout(() => {
        setErr(false)
      }, [5000]);
    }


  };
  
	return (
		<div className="flex items-center justify-center mx-auto flex-col  mt-10">
			<h1 className="text-2xl">Admin Login</h1>
			<div className="flex">
				<form action="" onSubmit={submitForm} className="flex flex-col gap-5 border p-5 ">
					<div className="flex gap-4 items-center justify-between">
						<label htmlFor="username">Email: </label>
						<input className="border outline-none p-1" type="email" name="email" id="email" onChange={(e)=>setEmail(e.target.value)} />
						<br />
					</div>
					<div className="flex gap-4 items-center justify-between">
						<label htmlFor="password">Password: </label>
						<input className="border outline-none p-1"  type="password" name="password" id="password" onChange={(e)=>setPassword(e.target.value)} />
          </div>
          <div className="mx-auto">
					  <button type="submit" className="border-blue-500 border py-2 px-3">{ loading ? "Loading":"Submit"}</button>
          </div>
        </form> 
        
			</div>
        {
          err && <div className="mt-5">Wrong Username or Password </div>
        }
		</div>
	);
}

export default AdminLogin;
