import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIndividualContext } from "../../context";
import ImageInput from "../../components/ImageInput";
import arrowLeft from "../../assets/ArrowLeft.svg";
import axios from "axios";
import otpSvg from "../../assets/Group 427319036.jpg";
import dlSvg from "../../assets/Group.svg";
import loadingGif from "../../assets/carLoading.gif";
import tickSvg from "../../assets/Group 427319018.svg";
import Error from "../../components/Error";
import { uploadFile } from "../../utils/fileUpload/imageUpload";


function VerifyAdhaar() {
	const navigate = useNavigate();
	const {
		selected_location,
		contact_number,
		dlnumber,
		dob,
		dl_front_img,
		dl_back_img,
		dl_number_checked,
		dl_number_data,
		partner_photo,
		pan_no,
		pan_img,
		aadhaar_no,
		aadhaar_front_img,
		aadhaar_back_img,
		aadhar_data,
		aadhar_checked,
		aadhar_skipped,
		dispatch,
	} = useIndividualContext();
	const [currentPage, setCurrentPage] = useState(3);
	const [loading, setLoading] = useState(false);
	const [aadharOTP, setAadharOTP] = useState("");
	const [aadharClientId, setAadharClientId] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const phoneRe = /^[0-9\b]+$/;

	const updateDriver = async (isAadharChecked, isAadharSkipped, aadharData) => {
		setLoading("Saving driver details");

		let driver_details = {
			selected_location,
			contact_number: contact_number,
			id: contact_number,
			isfleet: "false",
			isfleetdriver: "false",
			dlnumber: dlnumber,
			dl_front_img: dl_front_img,
			dl_back_img: dl_back_img,
			dl_number_checked: dl_number_checked,
			dl_number_data: dl_number_data,
			partner_photo: partner_photo,
			pan_no: pan_no,
			pan_img: pan_img,
			aadhaar_no: aadhaar_no,
			aadhaar_front_img: aadhaar_front_img,
			aadhaar_back_img: aadhaar_back_img,
			aadhar_data: aadharData,
			aadhar_checked: isAadharChecked,
			aadhar_skipped: isAadharSkipped,
		};


		

		let image_obj_name = [
			"dl_front_img",
			"dl_back_img",
			"partner_photo",
			"pan_img",
			"aadhaar_front_img",
			"aadhaar_back_img",
		];
		for (let index = 0; index < image_obj_name.length; index++) {
			console.log("in image upload loop");
			let element = image_obj_name[index];
			if (!driver_details[element]) {
				setLoading(false);
				setErrorMessage("All details are required");
				setLoading(false);
				return;
			}
			if (
				typeof driver_details[element] !== "string" 
				// !driver_details[element].includes(
				// 	"https://storage.cloud.google.com/app-gadigoda.appspot.com"
				// )
			) {
				let response = await uploadFile(driver_details[element]);
				console.log(response);
				if (response !== "failed") {
					driver_details[element] = response;
					dispatch({
						type: "SET_DATA",
						payload: { name: element, value: response },
					});
				} else {
					console.log(response);
					setLoading(false);
					setErrorMessage("Image upload failed. Please try again")
					return;
				}
			} else {
				console.log(driver_details[element]);
			}
		}
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/updateDriver`,
				driver_details
			);

			setLoading(false);
			console.log(response);
			console.log(driver_details);
			console.log("Navigation Start");
			navigate("/addindividual/verifyrc");
			console.log("Navigation End");
		} catch (error) {
			setLoading(false);
			setErrorMessage("Error ocurred. Please try again");
			console.log(error);
			setCurrentPage(3);
		}
	};

	async function sendOTPAadhaar() {
		if (
			!aadhaar_no ||
			!pan_no ||
			!partner_photo ||
			!pan_img ||
			!aadhaar_back_img ||
			!aadhaar_front_img
		) {
			setErrorMessage("Please enter all details");
			return;
		}
		const url = `https://kyc-api.surepass.io/api/v1/aadhaar-v2/generate-otp`;
		setLoading("Sending OTP to aadhar registered phone number");

		try {
			const response = await axios.post(
				url,
				{ id_number: aadhaar_no },
				{
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_SUREPASS_TOKEN}`,
					},
				}
			);
			console.log(response.data.success);
			if (response.data.success === true) {
				setAadharClientId(response.data.data.client_id);
				console.log(response);
				setCurrentPage(3.5);
				setLoading(false);
			} else {
				setErrorMessage("Failed to send otp. Please try again");
				setLoading(false);
				console.log("error", response);
			}
		} catch (e) {
			setErrorMessage("Failed to send otp. Please try again");
			setLoading(false);
			console.log("error", e);
		}
	}

	async function verifyAadhaar() {
		if (aadhar_checked) {
			updateDriver(aadhar_checked, aadhar_skipped, aadhar_data);
			return;
		}
		const url = `https://kyc-api.surepass.io/api/v1/aadhaar-v2/submit-otp`;
		setLoading("Verifying aadhar");
		try {
			const response = await axios.post(
				url,
				{ client_id: aadharClientId, otp: aadharOTP },
				{
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_SUREPASS_TOKEN}`,
					},
				}
			);
			if (response.data.success === true) {
				dispatch({
					type: "SET_DATA",
					payload: { name: "aadhar_data", value: response.data.data },
				});
				dispatch({
					type: "SET_DATA",
					payload: { name: "aadhar_checked", value: true },
				});
				dispatch({
					type: "SET_DATA",
					payload: { name: "aadhar_skipped", value: false },
				});

				updateDriver(true, false, response.data.data);
			} else {
				setErrorMessage("Failed to verify aadhaar. Please enter valid details");
				setLoading(false);
				console.log("error", response);
			}
		} catch (e) {
			setLoading(false);
			setErrorMessage("Failed to verify aadhaar. Please enter valid details");
			console.log(e);
		}
	}

	async function skipOTPProcess() {
    // debugger;
		// dispatch({ type: "SET_DATA", payload: { name: "aadhar_data", value: '' } });
		// debugger;
		// console.log('value of aadhar_skipped is ', aadhar_skipped)
		// debugger;
		console.log("Otp verification is skipped");
		// debugger;
		dispatch({ type: "SET_DATA", payload: { name: "aadhar_skipped", value: true } });
		// debugger;
		console.log("value of aadhar_skipped is ", aadhar_skipped);
		// debugger;
		updateDriver(aadhar_checked, true, "");
		// navigate("/addindividual/verifyrc");
	}
	const previewFile = (file) => {
		//Generate preview image from file

		return new Promise(function (resolve) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				return resolve(reader.result);
			};
		});
	};
	return (
		<>
			<Error errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
			<div className="w-screen h-screen bg-gray-100 overflow-scroll">
				<div className="max-w-[1000px] bg-white w-full m-auto min-h-screen p-5">
					<div className="flex items-center gap-2">
						<img
							src={arrowLeft}
							alt=""
							onClick={() => {
								if (currentPage == 3) {
									navigate("/addindividual/verifydl");
								} else {
									setCurrentPage(3);
								}
							}}
						/>
						<div className=" text-base font-semibold">
							Adding new individual Partner
						</div>
					</div>
					<div className="mt-5 relative">
						<div className="absolute h-full w-[2px] bg-gray-300 z-0 left-[14px] top-0 transition-all duration-500"></div>

						<div className="flex gap-3 items-center my-5 z-[1] relative">
							<img src={tickSvg} className="ml-1" alt="" />
							<div className=" text-sm">Enter and verify mobile number</div>
						</div>
						<div className="flex gap-3 items-center my-5 z-[1] relative">
							<img src={tickSvg} className="ml-1" alt="" />
							<div className=" text-sm">Enter and verify driver license</div>
						</div>
						<div className="flex gap-3 items-center my-5 z-[1] relative">
							<div className="  bg-yellow w-8 py-1 rounded-sm text-center">
								3
							</div>
							<div className=" text-sm">
								Hi{" "}
								{(dl_number_data.name != undefined || !dl_number_data.name) &&
									dl_number_data.name}
								, Let's complete your kyc
							</div>
						</div>
					</div>
					{loading === false ? (
						<div className=" pb-16">
							{currentPage == 3 && (
								<div>
									<div className=" text-xs -mb-3">
										Add Partner Photo for verification
										<span className=" text-[#DC3545]">*</span>
									</div>
									<ImageInput
										handleChange={(e) => {
											let file = e.target.files[0];
											dispatch({
												type: "SET_DATA",
												payload: { name: "partner_photo", value: file },
											});
										}}
										id="partner_img"
										image={partner_photo}
										labelText="Upload Your Photo"
										imgText="Partner photo"
										handleDel={() => {
											dispatch({
												type: "SET_DATA",
												payload: { name: "partner_photo", value: "" },
											});
										}}
									/>
									<span className="text-[10px] text-yellow">Image size sholud be less than 1mb</span>
									<div className=" w-full max-w-[500px] h-[2px] my-4 bg-gray-300 "></div>
									<div className=" text-xs mb-1">
										Enter PAN number<span className=" text-[#DC3545]">*</span>
									</div>
									<input
										type="text"
										placeholder="Eg: ABCCD12234Z"
										value={pan_no}
										className=" border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
										onChange={(e) => {
											dispatch({
												type: "SET_DATA",
												payload: { name: "pan_no", value: e.target.value },
											});
											console.log(e.target.value);
										}}
									/>
									<ImageInput
										handleChange={(e) => {
											let file = e.target.files[0];
											dispatch({
												type: "SET_DATA",
												payload: { name: "pan_img", value: file },
											});
										}}
										id="pan_img"
										image={pan_img}
										labelText="Upload Pan Image"
										imgText="Pan Card"
										handleDel={() => {
											dispatch({
												type: "SET_DATA",
												payload: { name: "pan_img", value: "" },
											});
										}}
									/>
									<span className="text-[10px] text-yellow">Image size sholud be less than 1mb</span>
									<div className=" w-full max-w-[500px] h-[2px] my-4 bg-gray-300 "></div>
									<div className=" text-xs mb-1">
										Enter Aadhar Number
										<span className=" text-[#DC3545]">*</span>
									</div>
									<input
										type="text"
										placeholder="Eg: 123390099080"
										value={aadhaar_no}
										className=" border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
										onChange={(e) => {
											dispatch({
												type: "SET_DATA",
												payload: { name: "aadhar_checked", value: false },
											});
											dispatch({
												type: "SET_DATA",
												payload: { name: "aadhaar_no", value: e.target.value },
											});
										}}
									/>
									<ImageInput
										handleChange={(e) => {
											let file = e.target.files[0];
											dispatch({
												type: "SET_DATA",
												payload: { name: "aadhaar_front_img", value: file },
											});
										}}
										id="aadhar_front_img"
										image={aadhaar_front_img}
										labelText="Upload Aadhar Front Image"
										imgText="Aadhar_front"
										handleDel={() => {
											dispatch({
												type: "SET_DATA",
												payload: { name: "aadhaar_front_img", value: "" },
											});
										}}
									/>
									<span className="text-[10px] text-yellow">Image size sholud be less than 1mb</span>

									<ImageInput
										handleChange={(e) => {
											let file = e.target.files[0];
											dispatch({
												type: "SET_DATA",
												payload: { name: "aadhaar_back_img", value: file },
											});
											console.log(file);
										}}
										id="aadhar_back_img"
										image={aadhaar_back_img}
										labelText="Upload Aadhar Back Image"
										imgText="Aadhar_back"
										handleDel={() => {
											dispatch({
												type: "SET_DATA",
												payload: { name: "aadhaar_back_img", value: "" },
											});
										}}
									/>
									<span className="text-[10px] text-yellow">Image size sholud be less than 1mb</span>
								</div>
							)}
							{/* if page is 3.5 then show aadhar otp verification page */}
							{currentPage == 3.5 && (
								<div className=" mb-4">
									<div className=" text-xs mb-1">
										Enter OTP sent to aadhar registered mobile number
										<span className=" text-[#dc3545]">*</span>
									</div>
									<input
										type="tel"
										maxLength={6}
										value={aadharOTP}
										placeholder="Enter 6 digit OTP"
										className=" border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
										onChange={(e) =>
											(phoneRe.test(e.target.value) || e.target.value === "") &&
											setAadharOTP(e.target.value)
										}
									/>
									<div className="mt-20">
										<img
											className="m-auto mb-3 translate-x-2"
											src={otpSvg}
											alt=""
										/>
										<div className="text-xs text-gray-500 text-center">
											Enter OTP to verify your aadhaar number
										</div>
									</div>
								</div>
							)}
						</div>
					) : (
						<div className=" w-[250px] mx-auto mt-20">
							{/* if loading then show loading gif */}
							<img
								className="w-[165px] h-[130px] mx-auto"
								src={loadingGif}
								alt=""
							/>
							<div className=" whitespace-normal text-xs text-gray-500 text-center">
								{loading}
							</div>
						</div>
					)}
					{loading === false && (
						<div className="fixed w-full max-w-[1000px] bottom-0 text-right left-1/2 -translate-x-1/2 p-4 pr-6 mb-2">
							{currentPage == 3 && (
								<button
									onClick={skipOTPProcess}
									className=" text-black border-2 bg-white mr-8 border-black rounded-md px-8 py-2"
								>
									Skip OTP
								</button>
							)}
							{currentPage == 3 && (
								<button
									onClick={sendOTPAadhaar}
									className=" bg-black text-white rounded-md px-8 py-2"
								>
									Continue
								</button>
							)}
							{currentPage == 3.5 && (
								<button
									onClick={() => {
										verifyAadhaar();
									}}
									className=" bg-black text-white rounded-md px-8 py-2"
								>
									Continue
								</button>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default VerifyAdhaar;
