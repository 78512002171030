import React from "react";

import sports_car_white from "../assets/sports_car_white.svg";

export const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className="px-4 py-6 w-full bg-black border-t-4 border-yellow">
      <div className="m-auto flex justify-center gap-3">
        <img src={sports_car_white} alt="" />
        <p className=" font-semibold text-white text-base">Gadigoda.com</p>
      </div>
      <div className="text-white text-center text-xs m-6">
        We're India's First RuralE-Cabbie Network. Our Mission is to digitize
        100 Million Taxi Owners to start their business Online.
      </div>
      <div className="py-1 px-2 bg-yellow w-max m-auto rounded-md">
        Contact Us:
      </div>
      <div className="text-white text-center text-sm mt-2">
        Phone:{" "}
        <a href="tel:7627013682" className="text-white underline">
          762-701-3682
        </a>{" "}
        |{" "}
        <a href="tel:7666088088" className="text-white underline">
          766-608-8088
        </a>
      </div>
      <div className="bg-white h-[2px] m-6"></div>
      <div className=" text-white text-center text-sm mt-2">
        Terms and conditions | Privacy Policy
      </div>
      <div className="text-white text-center text-xs mt-4">
        © {year} gadigoda. All Rights Reserved.
      </div>
    </div>
  );
};
