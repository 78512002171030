import React, { useEffect, useState } from 'react';
import Entity from '../components/EntityContext';
import Modal from 'react-modal';
import arrowLeft from "../assets/ArrowLeft.svg";
import tickSvg from "../assets/Group 427319018.svg";
import otpSvg from "../assets/Group 427319036.jpg";
import dlSvg from "../assets/Group.svg";
import loadingGif from "../assets/carLoading.gif";
import ImageInput from '../components/ImageInput';
import { useNavigate } from 'react-router-dom';
import crossSvg from "../assets/Vector (1) copy.svg"
import axios from 'axios';
import TermsConditions from '../components/TermsConditions';
export default function AddDriver() {
    //DL States
    const navigate = useNavigate();
    
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    // Phone number verification states
    const [phoneno, setPhoneno] = useState("");
    const [correctOTP, setCorrectOTP] = useState("");
    const [newUser, setNewUser] = useState(true);
    const [mobileOTP, setMobileOTP] = useState("");
    //License verification state
    const [dlnumber, setDLNumber] = useState("")
    const [dob, setDob] = useState("");
    const [dlFrontImg, setDLFrontImg] = useState("")
    const [dlBackImg, setDLBackImg] = useState("")
    const [dlnumberchecked, setDLNumberChecked] = useState(false)
    const [dlnumberdata, setDLNumberData] = useState("")

    //kyc, verification States
    const [partnerPhoto, setPartnerPhoto] = useState("");
    const [panno, setPanno] = useState("");
    const [panImg, setPanImg] = useState("");
    const [aadhar, setAadhar] = useState("");
    const [aadharBackImg, setAadharBackImg] = useState("");
    const [aadharFrontImg, setAadharFrontImg] = useState("");
    const [aadharOTP, setAadharOTP] = useState("");
    const [aadharClientId, setAadharClientId] = useState("");
    const [aadhaarData, setAadharData] = useState("");
    const [otpmdoal, setOtpModal] = useState(false);
    const [aadhaarChecked, setAadharChecked] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    // car verification state
    const [carAvailable, setCarAvailable] = useState(true);
    const [plateno, setPlateno] = useState("");
    const [plateImg, setPlateImg] = useState("");
    const [carData, setCarData] = useState("");
    const [carrierAvailable, setCarrierAvailable] = useState(false);
    const [isRcVerified, setIsRcVerified] = useState(false);
    const [isAgreed, setIsAgreed] = useState(false);
    // console.log(carAvailable);
    const phoneRe = /^[0-9\b]+$/;
    const progressText = [
        "Enter and verify your mobile",
        "Enter your license details",
        `Hi, Let's Complete your KYC`,
        "Let's add your car details"
    ]
    // const msg91TemplateId = '61eeca20e73da90b34413fa2';
    // const msg91AuthKey = '372225AtKUWZBhw61ecdf4eP1';
    const { entity } = Entity()

    // async function addDriver() {
    //     if (aadhaarchecked && dlnumberchecked) {
    //         const url = ``
    //         try {
    //             const response = await fetch(url, {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                 },
    //                 body:
    //                 {
    //                     key_dl : localStorage.getItem('dlnumber'),
    //                     data: {
    //                         aadhaar_data : aadhaardata,
    //                         dl_data : dlnumberdata,
    //                         dl_image: dlimg,
    //                         vehicle : entity,
    //                     },
    //                     type : "driver"
    //                 },
    //             });
    //             if (response.status === 200) {
    //                 console.log("Driver Data Added")
    //             }
    //             else {
    //                 console.log("Error Occured in Adding Driver")
    //             }
    //         }
    //         catch {
    //             console.log('API Error Occured')
    //         }
    //     }
    //     else if (aadhaarchecked == false)
    //         setErrorMsg("Kindly Verify Aadhaar Number before Proceeding")
    //     else if (dlnumberchecked == false)
    //         setErrorMsg("Kindly Verify Driving Licence Number before Proceeding")
    // }
    async function verifyDL() {
        const url = `https://kyc-api.surepass.io/api/v1/driving-license/driving-license`
        setLoading("Verifying license number");
        try {
            const response = await axios.post(url, { id_number: dlnumber, dob: dob }, { headers: { "Authorization": `Bearer ${process.env.REACT_APP_SUREPASS_TOKEN}` } })
            console.log(response);
            if (response.data.message_code === "success") {
                // setOtpError(false)
                setDLNumberData(response.data.data);
                console.log(response);
                setDLNumberChecked(true);
                setLoading(false);
                updateUser("dl");
            }
            else {
                setLoading(false);
                console.log(response);
                // setOtpError(true)
                alert("Some error occured or invalid dl number");
            }
        }
        catch (error) {
            setLoading(false);
            console.log(error);
            alert("Some error occured or invalid dl number");
        }
    }
    async function sendOTPAadhaar() {
        const url = `https://kyc-api.surepass.io/api/v1/aadhaar-v2/generate-otp`
        setLoading("Sending OTP to aadhar registered phone number");
        try {
            const response = await axios.post(url, { id_number: aadhar }, { headers: { "Authorization": `Bearer ${process.env.REACT_APP_SUREPASS_TOKEN}` } })
            console.log(response.data.success);
            if (response.data.success === true) {
                setAadharClientId(response.data.data.client_id)
                console.log(response)
                setCurrentPage(3.5);
                setLoading(false);

                // setOtpModal(true)
            }
            else {
                alert('some error occurred')
                setLoading(false);
                console.log('error', response);
            }
        }
        catch (e) {
            alert('some error occurred')
            setLoading(false);
            console.log('error', e)
        }
    }

    async function verifyAadhaar() {
        const url = `https://kyc-api.surepass.io/api/v1/aadhaar-v2/submit-otp`;
        setLoading("Verifying aadhar");
        try {
            const response = await axios.post(url, { client_id: aadharClientId, otp: aadharOTP }, { headers: { "Authorization": `Bearer ${process.env.REACT_APP_SUREPASS_TOKEN}` } });
            if (response.data.success === true) {
                setAadharData(response.data.data)
                setAadharChecked(true)
                setLoading(false);
                setCurrentPage(4);
                console.log(response);
                
            }
            else {
                setLoading(false);
                console.log('error', response)
            }

        }
        catch (e) {
            setLoading(false);
            console.log(e);
        }
    }
    async function updateUser(e) {
        if (e === "dl") {
            setCurrentPage(3);
        }
    }

    const sendOTPMobile = async () => {
        if (phoneno.length < 10) {
            alert("Invalid mobile number");
            return;
        }
        setLoading("Sending otp on mobile number");
        try{
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/sendPartnerOTP`, {number: phoneno})

            
            console.log(response);
            setCorrectOTP(response.data.otp)
            setNewUser(response.data.new_partner)
            // newUser = true;
            setCurrentPage(1.5);
            setLoading(false);
        }
        catch(e){
            setLoading(false);
            console.log(e);
        }
        


    }
    const addPartner = async () => {
        setLoading("Saving contact info");
        try {
            let response = await axios.post(`${process.env.REACT_APP_BASE_URL}/addPartner`,
                {
                    
                    contact_number: phoneno,
                    id: phoneno
                    
                }
            )
            console.log(response);
            setCurrentPage(2);
            setLoading(false);
        }
        catch (e) {
            alert("Some Error Occured")
            setLoading(false);
            console.log(e);
        }
    }
    const verifyMobileOTP = () => {
        if (mobileOTP == correctOTP) {
            if (newUser == true) {
                addPartner();
            }
            else{
                setCurrentPage(2);
            }
        }
        else {
            alert('Invalid OTP');
        }
    }

    const verifyRC = async () => {
        const url = `https://kyc-api.surepass.io/api/v1/rc/rc-lite-v2`;
        setLoading("Verifying RC plate number");
        try {
            const response = await axios.post(url, { id_number: plateno }, { headers: { "Authorization": `Bearer ${process.env.REACT_APP_SUREPASS_TOKEN}` } });
            if (response.data.success === true) {
                setCarData(response.data.data)
                setLoading(false);
                console.log(response);
            }
            else {
                setLoading(false);
                console.log('error', response)
            }

        }
        catch (e) {
            setLoading(false);
            console.log(e);
        }
    }
    const callFunction = () => {
        if (currentPage == 1.5) {
            verifyMobileOTP();
            console.log(correctOTP);
        }
        else if (currentPage == 2) {
            if (dlnumber.trim() && dlBackImg && dlFrontImg && dob) {
                verifyDL();
            }
            else {
                alert("All details are required");
            }
        }
        else if (currentPage == 3) {
            // partnerPhoto && panno && panImg && aadhar && aadharBackImg && aadharFrontImg
            if (partnerPhoto && panno && panImg && aadhar && aadharBackImg && aadharFrontImg) {
                sendOTPAadhaar();
            }
            else {
                alert("All details are required");
            }
        }
        else if (currentPage == 3.5) {
            verifyAadhaar();

        }
        else if (currentPage == 4) {
            verifyRC();
        }
    }
    const previewFile = (file) => { //Generate preview image from file

        return new Promise(function (resolve) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                return resolve(reader.result)
            }
        });
    }
    const customStyles = {
        content: {
        
        inset: '50% -100px -100px 50%',
          
        //   marginRight: '-50%',
        //   overflow: "scroll",
          padding: "0px",
          transform: 'translate(-50%, -50%)',
          "max-height": '100%',
          "max-width": '400px'
        },
        overlay: {
            background: "rgb(0 0 0 / 70%)",
            "z-index": "3"
          }
      };
    return (
        <>
        <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => {
                    setIsOpen(true);
                }}
        onRequestClose={() => {
                    setIsOpen(false);
                }}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className=' p-5'>
            <div className='flex justify-between items-center'>
                <div className='flex-1 text-base font-semibold'>Terms and Conditions</div>
                <img className=' cursor-pointer' onClick={() => {
                    setIsOpen(false);
                }} src={crossSvg} alt="" />
            </div>
            <div>
                <h1 className='mt-4'>PRIVACY</h1>
                <p className=' text-xs'>This Site may contain links to other independent third-party Web sites ("Linked Sites”). These Linked Sites are provided solely as a convenience to our visitors. Such Linked Sites are not under our control, and we are not responsible for and does not endorse the content of such Linked Sites, including any information or materials contained on such Linked Sites. You will need to make your own independent judgment regarding your interaction with these Linked Sites.</p>
            </div>
            <div className=' pb-10'>
                <h1 className='mt-4'>PRIVACY</h1>
                <p className=' text-xs'>This Site may contain links to other independent third-party Web sites ("Linked Sites”). These Linked Sites are provided solely as a convenience to our visitors. Such Linked Sites are not under our control, and we are not responsible for and does not endorse the content of such Linked Sites, including any information or materials contained on such Linked Sites. You will need to make your own independent judgment regarding your interaction with these Linked Sites.</p>
            </div>
        </div>
        
        <div className=' sticky bottom-0 p-4 bg-white border w-full'>
            <button className='rounded-md bg-black text-white py-[6px] w-full'>Accept and Continue</button>
        </div>
      </Modal>
            {/* <TermsConditions 
                modalIsOpen = {modalIsOpen}  
                afterOpenModal = {() => {
                    setIsOpen(true);
                }}
                closeModal = {() => {
                    setIsOpen(false);
                }}

            /> */}
            <div className='w-screen h-screen bg-gray-100 overflow-scroll'>
                <div className='max-w-[1000px] bg-white w-full m-auto min-h-screen p-5'>
                    <div className='flex items-center gap-2'>
                        <img src={arrowLeft} alt="" onClick={() => {
                            if (currentPage == 1) {
                                navigate("/registeroption")
                            }
                            else if (currentPage == 1.5) {
                                setCurrentPage(1);
                            }
                            else if (currentPage == 3.5) {
                                setCurrentPage(3)
                            }
                            else {
                                let page = currentPage - 1;
                                setCurrentPage(page);
                            }
                        }} />
                        <div className=' text-base font-semibold'>Adding new individual Partner</div>
                    </div>
                    {/* code for showing progress */}
                    <div className='mt-5 relative'>
                        <div style={{ height: (parseInt(currentPage) - 1) * 50 + "px" }} className='absolute w-[2px] bg-gray-300 z-0 left-[14px] top-0 transition-all duration-500'></div>
                        {progressText.map((e, i) => {
                            if (i == 0 || i == 2) {
                                return (<div className='flex gap-3 items-center my-5 z-[1] relative'>
                                    {(currentPage < i + 2 && currentPage >= i + 1) && <div className='  bg-yellow w-8 py-1 rounded-sm text-center'>{i + 1}</div>}
                                    {(currentPage >= i + 2) && <img src={tickSvg} className='ml-1' alt="" />}
                                    {(currentPage >= i + 1) && <div className=' text-sm'>{e}</div>}
                                </div>)
                            }
                            else if (currentPage >= i + 1) {
                                return (<div className='flex gap-3 items-center my-5 z-[1] relative'>
                                    {(currentPage == i + 1) && <div className='  bg-yellow w-8 py-1 rounded-sm text-center'>{i + 1}</div>}
                                    {(currentPage >= i + 2) && <img src={tickSvg} className='ml-1' alt="" />}
                                    {(currentPage >= i + 1) && <div className=' text-sm'>{e}</div>}
                                </div>)
                            }
                        })}
                    </div>
                    {/* if loading is false then show the form */}
                    {(loading === false) ? <div className=' pb-16'>
                        {/* if page is 1 the show mobile number input form */}
                        {(currentPage == 1) && <div className=' mb-4'>
                            <div className=' text-xs mb-1'>Mobile number<span className=' text-[#DC3545]'>*</span></div>
                            <input type="tel"
                                maxLength={10}
                                value={phoneno}
                                placeholder='Enter your mobile number'
                                className=' border border-gray-500 bg-gray-100 p-2 rounded-md w-full'
                                onChange={(e) => (phoneRe.test(e.target.value) || e.target.value === "") && setPhoneno(e.target.value)} />

                        </div>}
                        {/* if page is 1.5 then show mobile number otp page  */}
                        {(currentPage == 1.5) && <div className=' mb-4'>
                            <div className=' text-xs mb-1'>Enter OTP<span className=' text-[#DC3545]'>*</span></div>
                            <input type="tel"
                                maxLength={6}
                                value={mobileOTP}
                                placeholder='Enter OTP sent to mobile number'
                                className=' border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]'
                                onChange={(e) => (phoneRe.test(e.target.value) || e.target.value === "") && setMobileOTP(e.target.value)} />
                            <div className='mt-20'>
                                <img className='m-auto mb-3 translate-x-2' src={otpSvg} alt="" />
                                <div className='text-xs text-gray-500 text-center'>Enter OTP to verify your Number</div>
                            </div>
                        </div>}
                        {/* if page is 2 then show driver license verification form/page */}
                        {(currentPage == 2) && <div className=' mb-4'>
                            <div className=' text-xs mb-1'>Enter DL Number<span className=' text-[#DC3545]'>*</span></div>
                            <input type="text"
                                placeholder='Eg: KA1234567899029'
                                value={dlnumber}
                                className=' border mb-4 border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]'
                                onChange={(e) => setDLNumber(e.target.value)}
                            />
                            <div className=' text-xs mb-1'>Enter Date of Birth<span className=' text-[#DC3545]'>*</span></div>
                            <input type="date"

                                value={dob}
                                className=' border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]'
                                onChange={(e) => {
                                    setDob(e.target.value);
                                    console.log(e.target.value)
                                }}
                            />
                            {/* component for taking image input */}
                            <ImageInput
                                handleChange={(e) => {
                                    let file = e.target.files[0];
                                    if (file) {
                                        previewFile(file).then((value) => {
                                            setDLFrontImg(value);
                                            console.log(value);
                                        })
                                    }
                                }}
                                id="dl_front_img"
                                image={dlFrontImg}
                                labelText="Upload License Front Image"
                                imgText="License Front"
                                handleDel={() => {
                                    setDLFrontImg("");
                                }}
                            />
                            <ImageInput
                                handleChange={(e) => {
                                    let file = e.target.files[0];
                                    if (file) {
                                        previewFile(file).then((value) => {
                                            setDLBackImg(value);
                                            console.log(value);
                                        })
                                    }
                                }}
                                id="dl_back_img"
                                image={dlBackImg}
                                labelText="Upload License Back Image"
                                imgText="License Back"
                                handleDel={() => {
                                    setDLBackImg("");
                                }}
                            />
                            <div className='mt-20'>
                                <img className='m-auto mb-3 translate-x-2' src={dlSvg} alt="" />
                                <div className='text-xs text-gray-500 text-center'>Upload your License Number and photo</div>
                            </div>
                        </div>}
                        {/* if page is 3 then show kyc form/page */}
                        {(currentPage == 3) && <div>
                            <div className=' text-xs -mb-3'>Add Partner Photo for verification<span className=' text-[#DC3545]'>*</span></div>
                            <ImageInput
                                handleChange={(e) => {
                                    let file = e.target.files[0];
                                    if (file) {
                                        previewFile(file).then((value) => {
                                            setPartnerPhoto(value);
                                            console.log(value);
                                        })
                                    }
                                }}
                                id="partner_img"
                                image={partnerPhoto}
                                labelText="Upload Your Photo"
                                imgText="Partner photo"
                                handleDel={() => {
                                    setPartnerPhoto("");
                                }}
                            />
                            <div className=' w-full max-w-[500px] h-[2px] my-4 bg-gray-300 '></div>
                            <div className=' text-xs mb-1'>Enter PAN number<span className=' text-[#DC3545]'>*</span></div>
                            <input type="text"
                                placeholder='Eg: ABCCD12234Z'
                                value={panno}
                                className=' border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]'
                                onChange={(e) => {
                                    setPanno(e.target.value);
                                    console.log(e.target.value);
                                }}
                            />
                            <ImageInput
                                handleChange={(e) => {
                                    let file = e.target.files[0];
                                    if (file) {
                                        previewFile(file).then((value) => {
                                            setPanImg(value);
                                            console.log(value);
                                        })
                                    }
                                }}
                                id="pan_img"
                                image={panImg}
                                labelText="Upload Pan Image"
                                imgText="Pan Card"
                                handleDel={() => {
                                    setPanImg("");
                                }}
                            />
                            <div className=' w-full max-w-[500px] h-[2px] my-4 bg-gray-300 '></div>
                            <div className=' text-xs mb-1'>Enter Aadhar Number<span className=' text-[#DC3545]'>*</span></div>
                            <input type="text"
                                placeholder='Eg: 123390099080'
                                value={aadhar}
                                className=' border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]'
                                onChange={(e) => {
                                    setAadhar(e.target.value);

                                }}
                            />
                            <ImageInput
                                handleChange={(e) => {
                                    let file = e.target.files[0];
                                    if (file) {
                                        previewFile(file).then((value) => {
                                            setAadharFrontImg(value);

                                        })
                                    }
                                }}
                                id="aadhar_front_img"
                                image={aadharFrontImg}
                                labelText="Upload Aadhar Front Image"
                                imgText="Aadhar_front"
                                handleDel={() => {
                                    setAadharFrontImg("");
                                }}
                            />

                            <ImageInput
                                handleChange={(e) => {
                                    let file = e.target.files[0];
                                    if (file) {
                                        previewFile(file).then((value) => {
                                            setAadharBackImg(value);

                                        })
                                    }
                                }}
                                id="aadhar_back_img"
                                image={aadharBackImg}
                                labelText="Upload Aadhar Back Image"
                                imgText="Aadhar_back"
                                handleDel={() => {
                                    setAadharBackImg("");
                                }}
                            />
                        </div>}
                        {/* if page is 3.5 then show aadhar otp verification page */}
                        {(currentPage == 3.5) && <div className=' mb-4'>
                            <div className=' text-xs mb-1'>Enter OTP sent to aadhar registered mobile number<span className=' text-[#dc3545]'>*</span></div>
                            <input type="tel"
                                maxLength={6}
                                value={aadharOTP}
                                placeholder='Enter 6 digit OTP'
                                className=' border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]'
                                onChange={(e) => (phoneRe.test(e.target.value) || e.target.value === "") && setAadharOTP(e.target.value)} />
                            <div className='mt-20'>
                                <img className='m-auto mb-3 translate-x-2' src={otpSvg} alt="" />
                                <div className='text-xs text-gray-500 text-center'>Enter OTP to verify your aadhaar number</div>
                            </div>
                        </div>}
                        {(currentPage === 4) && <div>
                            <div className='flex items-center'>
                                <input
                                    className='w-4 h-4'
                                    type="checkbox"
                                    checked={!carAvailable}
                                    id='car_available'
                                    onChange={(e) => setCarAvailable(!(e.target.checked))}
                                />
                                <label htmlFor='car_available' className='pl-2 text-sm'>
                                    I don’t have a car
                                </label>
                            </div>
                            <div className=' w-full max-w-[500px] h-[2px] my-4 bg-gray-300 '></div>
                            {(carAvailable) && <><div className=' text-xs mb-1'>License Plate Number<span className=' text-[#DC3545]'>*</span></div>
                                <input type="text"
                                    placeholder='Eg: MH091823'
                                    value={plateno}
                                    className=' border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]'
                                    onChange={(e) => {
                                        setPlateno(e.target.value);

                                    }}
                                />
                                <ImageInput
                                    handleChange={(e) => {
                                        let file = e.target.files[0];
                                        if (file) {
                                            previewFile(file).then((value) => {
                                                setPlateImg(value);

                                            })
                                        }
                                    }}
                                    id="plate_img"
                                    image={plateImg}
                                    labelText="Upload License Plate Image"
                                    imgText="license_plate"
                                    handleDel={() => {
                                        setPlateImg("");
                                    }}
                                />
                                <div className='flex items-center mt-4'>
                                    <input
                                        className='w-4 h-4'
                                        type="checkbox"
                                        checked={carrierAvailable}
                                        id='carrier_available'
                                        onChange={(e) => setCarrierAvailable((e.target.checked))}
                                    />
                                    <label htmlFor='carrier_available' className='pl-2 text-sm'>
                                        Do your car have Carrier
                                    </label>
                                </div>
                                <button 
                                    onClick={() => {
                                        
                                    }}>
                                    Verify and Save
                                </button>
                            </>
                            }
                        </div>}
                    </div> :
                        <div className=' w-[250px] mx-auto mt-20'>
                            {/* if loading then show loading gif */}
                            <img className='w-[165px] h-[130px] mx-auto' src={loadingGif} alt="" />
                            <div className=' whitespace-normal text-xs text-gray-500 text-center'>{loading}</div>
                        </div>
                    }


                    {/* if loading is false then only show the buttons for submitting details */}
                    {(loading === false) && <div className='fixed w-full max-w-[1000px] bottom-0 text-right left-1/2 -translate-x-1/2 p-4 pr-6 mb-2'>

                        {(currentPage == 1) && <button onClick={sendOTPMobile} className=' bg-black text-white rounded-md px-8 py-2'>Send OTP</button>}
                        {(currentPage > 1 && currentPage <= 3.5) && <button onClick={callFunction} className=' bg-black text-white rounded-md px-8 py-2'>Continue</button>}
                        {(currentPage == 4 && isRcVerified) && <button onClick={callFunction} className=' bg-black text-white rounded-md px-8 py-2'>Complete</button>}
                    </div>}
                </div>
            </div>
        </>
    )
}
