import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIndividualContext } from "../../context";
import ImageInput from "../../components/ImageInput";
import Error from "../../components/Error";

import axios from "axios";

import dlSvg from "../../assets/Group.svg";
import tickSvg from "../../assets/Group 427319018.svg";

import { BackNav, LoaderScreen } from "../../components";

function VerifyDL() {
  const navigate = useNavigate();
  const {
    dlnumber,
    dob,
    dl_front_img,
    dl_back_img,
    dl_number_checked,
    dl_number_data,
    dispatch,
  } = useIndividualContext();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [maxDate, setMaxDate] = useState("");
  useEffect(() => {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 18);
    const maxDate = currentDate.toISOString().split("T")[0];
    setMaxDate(maxDate);
  }, []);
  async function verifyDL() {
    if (!dlnumber || !dob || !dl_front_img || !dl_back_img) {
      setErrorMessage("All details are required to be filled");
      return;
    }
    const url = `https://kyc-api.surepass.io/api/v1/driving-license/driving-license`;
    setLoading("Verifying license number");
    try {
      const response = await axios.post(
        url,
        { id_number: dlnumber, dob: dob },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SUREPASS_TOKEN}`,
          },
        }
      );
      console.log(response);
      if (response.data.message_code === "success") {
        // setOtpError(false)
        dispatch({
          type: "SET_DATA",
          payload: { name: "dl_number_data", value: response.data.data },
        });
        console.log(response);
        dispatch({
          type: "SET_DATA",
          payload: { name: "dl_number_checked", value: true },
        });
        setLoading(false);
        navigate("/addindividual/verifyadhaar");
      } else {
        setLoading(false);
        console.log(response);
        setErrorMessage("Invalid dl number. Please enter valid details");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setErrorMessage("Invalid dl number or network error. Please try again");
    }
  }
  const previewFile = (file) => {
    //Generate preview image from file

    return new Promise(function (resolve) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        return resolve(reader.result);
      };
    });
  };
  return (
    <>
      <Error errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
      <div className="w-screen h-screen bg-gray-100 overflow-scroll">
        <div className="max-w-[1000px] bg-white w-full m-auto min-h-screen p-5">
          <BackNav
            title="Adding new individual Partner"
            onBack={() => navigate("/addindividual")}
          />

          <div className="mt-5 relative">
            <div className="absolute h-full w-[2px] bg-gray-300 z-0 left-[14px] top-0 transition-all duration-500"></div>

            <div className="flex gap-3 items-center my-5 z-[1] relative">
              <img src={tickSvg} className="ml-1" alt="" />
              <div className=" text-sm">Enter and verify mobile number</div>
            </div>
            <div className="flex gap-3 items-center my-5 z-[1] relative">
              <div className="  bg-yellow w-8 py-1 rounded-sm text-center">
                2
              </div>
              <div className=" text-sm">Enter and verify driver license</div>
            </div>
          </div>
          {loading === false ? (
            <div className=" pb-16">
              {/* if page is 2 then show driver license verification form/page */}
              {
                <div className=" mb-4">
                  <div className=" text-xs mb-1">
                    Enter DL Number<span className=" text-[#DC3545]">*</span>
                  </div>
                  <input
                    type="text"
                    placeholder="Eg: KA1234567899029"
                    value={dlnumber}
                    className=" border mb-4 border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
                    onChange={(e) =>
                      dispatch({
                        type: "SET_DATA",
                        payload: { name: "dlnumber", value: e.target.value },
                      })
                    }
                  />
                  <div className=" text-xs mb-1">
                    Enter Date of Birth
                    <span className=" text-[#DC3545]">*</span>
                  </div>
                  <input
                    type="date"
                    max={maxDate}
                    value={dob}
                    className=" border border-gray-500 bg-gray-100 p-2 rounded-md w-full max-w-[500px]"
                    onChange={(e) => {
                      dispatch({
                        type: "SET_DATA",
                        payload: { name: "dob", value: e.target.value },
                      });
                      console.log(e.target.value);
                    }}
                  />
                  {/* component for taking image input */}
                  <ImageInput
                    handleChange={(e) => {
                      let file = e.target.files[0];
                      dispatch({
                        type: "SET_DATA",
                        payload: { name: "dl_front_img", value: file },
                      });
                    }}
                    id="dl_front_img"
                    image={dl_front_img}
                    labelText="Upload License Front Image"
                    imgText="License Front"
                    handleDel={() => {
                      dispatch({
                        type: "SET_DATA",
                        payload: { name: "dl_front_img", value: "" },
                      });
                    }}
                  />
                  <span className="text-[10px] text-yellow">
                    Image size sholud be less than 1mb
                  </span>
                  <ImageInput
                    handleChange={(e) => {
                      let file = e.target.files[0];
                      dispatch({
                        type: "SET_DATA",
                        payload: { name: "dl_back_img", value: file },
                      });
                    }}
                    id="dl_back_img"
                    image={dl_back_img}
                    labelText="Upload License Back Image"
                    imgText="License Back"
                    handleDel={() => {
                      dispatch({
                        type: "SET_DATA",
                        payload: { name: "dl_back_img", value: "" },
                      });
                    }}
                  />
                  <span className="text-[10px] text-yellow">
                    Image size sholud be less than 1mb
                  </span>
                  <div className="mt-20">
                    <img
                      className="m-auto mb-3 translate-x-2"
                      src={dlSvg}
                      alt=""
                    />
                    <div className="text-xs text-gray-500 text-center">
                      Upload your License Number and photo
                    </div>
                  </div>
                </div>
              }
            </div>
          ) : (
            <LoaderScreen message={loading} />
          )}
          {loading === false && (
            <div className="fixed w-full max-w-[1000px] bottom-0 text-right left-1/2 -translate-x-1/2 p-4 pr-6 mb-2">
              <button
                onClick={verifyDL}
                className=" bg-black text-white rounded-md px-8 py-2"
              >
                Continue
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default VerifyDL;
