import React, { createContext, useContext, useEffect, useState } from 'react'

const EntityContext = createContext();
export function EntityProvider({ children }) {

    const [entity, setEntity] = useState("loading");

    return (
        <EntityContext.Provider value={{ entity, setEntity }}>
            {children}
        </EntityContext.Provider>
    )
}
function Entity() {
    return useContext(EntityContext)
}

export default Entity