import React, { useEffect, useState } from "react";
import arrowLeft from "../../assets/ArrowLeft.svg";
import { useNavigate } from "react-router-dom";
import tickSvg from "../../assets/Group 427319018.svg";
import empty_dashboard_driver from "../../assets/empty_dashboard_driver.svg";
import empty_dashboard_car from "../../assets/taxi 1.svg";
import add_icon from "../../assets/add_icon.svg";
import { useFleetContext } from "../../context";
import loadingGif from "../../assets/carLoading.gif";
import axios from "axios";
import Error from "../../components/Error";
import Done from "../../components/Done";

export default function FleetDashboard() {
	const navigate = useNavigate();
	const { contact_number, name } = useFleetContext();
	const [currentPage, setCurrentPage] = useState(3);
	const [select, setSelect] = useState(1);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	const [driverlist, setDriverList] = useState([]);
	const [carlist, setCarList] = useState([
		// {
		//   vehicle_number: "ds",
		//   car_data:{
		//     maker_model:"f"
		//   },
		//   vehicle_location: "mumbai"
		// }
  ]);
  const [doneModal, setDoneModal] = useState(false);
  
  // console.log('value of doneModal ', doneModal);

	async function getDrivers() {
		setLoading("Fetching Drivers");
		try {
			let response = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/getDriversByContactNumber`,
				{
					contact_number: contact_number,
				}
			);
			console.log(response.data.drivers);
			setDriverList(response.data.drivers);
			setLoading(false);
		} catch (e) {
			setError("Some Error Occured");
			setLoading(false);
			console.log(e);
		}
	}

	async function getCars() {
		setLoading("Fetching Cars");
		try {
			let response = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/getCarsByContactNumber`,
				{
					contact_number: contact_number,
					is_fleet_car: true,
				}
			);
			console.log(response.data.cars);
			setCarList(response.data.cars);
			setLoading(false);
		} catch (e) {
			setError("Some Error Occured");
			setLoading(false);
			console.log(e);
		}
	}

	useEffect(() => {
		if (select == 1) getDrivers();
		else getCars();
	}, [select]);

	return (
		<>
      <Error errorMessage={error} setErrorMessage={setError} />
      
			<div className="w-screen h-screen bg-gray-100 overflow-scroll">
				<div className="max-w-[1000px] bg-white w-full m-auto min-h-screen p-5">
					<div className="flex items-center gap-2">
						<img
							src={arrowLeft}
							alt=""
							onClick={() => {
								console.log(currentPage);
								if (currentPage == 3) {
									navigate("addfleet/addbankdetails");
								} else if (currentPage == 3.5) {
									setCurrentPage(1);
								}
							}}
						/>
						<div className=" text-base font-semibold">
							Adding new individual Partner
						</div>
					</div>
					<div className="mt-5 relative">
						<div className="absolute h-full w-[2px] bg-gray-300 z-0 left-[14px] top-0 transition-all duration-500"></div>

						<div className="flex gap-3 items-center my-5 z-[1] relative">
							<img src={tickSvg} className="ml-1" alt="" />
							<div className=" text-sm">Enter your basic details</div>
						</div>
						<div className="flex gap-3 items-center my-5 z-[1] relative">
							<img src={tickSvg} className="ml-1" alt="" />
							<div className=" text-sm">Hi {name}, complete your kyc</div>
						</div>
						<div className="flex gap-3 items-center my-5 z-[1] relative">
							<div className="  bg-yellow w-8 py-1 rounded-sm text-center">
								3
							</div>
							<div className=" text-sm">Let's add your Drivers and cars</div>
						</div>
					</div>

					{loading == false ? (
						<div className="">
							<div className="my-4 flex justify-around border-[1px]  border-solid border-[black] rounded-[7px]">
								<button
									className={`py-2 rounded-[7px]  w-full ${
										select == 1 ? "text-[white] bg-black" : ""
									}`}
									onClick={() => setSelect(1)}
								>
									Driver
								</button>
								<button
									className={`py-2 rounded-[7px] w-full ${
										select == 2 ? "text-[white] bg-black" : ""
									}`}
									onClick={() => setSelect(2)}
								>
									Car
								</button>
							</div>
							{select == 1 ? (
								driverlist.length == 0 ? (
									<div className="mt-12 flex flex-col justify-center items-center">
										<img
											className="h-16 w-16"
											src={empty_dashboard_driver}
											alt=""
										/>
										<p className="text-[small] font-light mt-4">
											Register your drivers by adding their details
										</p>
										<button
											className="py-2 px-8 mt-4 rounded-[7px] border-black border-[1px] flex "
											onClick={() => {
												console.log("0");
												navigate("/addfleet/addfleetdriver");
											}}
										>
											<img className="mr-2" src={add_icon} alt="" />
											Add Driver
										</button>
									</div>
								) : (
									<div className="w-full">
										{driverlist.map((i) => {
											console.log(i);
											return (
												<DriverCard
													name={i?.aadhar_data?.full_name}
													mobile={i?.contact_number}
												/>
											);
										})}
										<div className="flex justify-center">
											<button
												className="py-2 px-8 mt-4 rounded-[7px] border-black border-[1px] flex"
												onClick={() => {
													navigate("/addfleet/addfleetdriver");
												}}
											>
												<img className="mr-2" src={add_icon} alt="" />
												Add Driver
											</button>
										</div>
									</div>
								)
							) : carlist.length == 0 ? (
								<div className="mt-12 flex flex-col justify-center items-center">
									<img className="h-16 w-16" src={empty_dashboard_car} alt="" />
									<p className="text-[small] font-light mt-4">
										Register your car by adding its license number
									</p>
									<button
										className="py-2 px-8 mt-4 rounded-[7px] border-black border-[1px] flex "
										onClick={() => navigate("/addfleet/addfleetcar")}
									>
										<img className="mr-2" src={add_icon} alt="" />
										Add Car
									</button>
								</div>
							) : (
								<div className="w-full">
									{carlist.map((i) => {
										console.log(i);
										return (
											<CarCard
												car_number={i.vehicle_number}
												model={i.car_data.maker_model}
												location={i.vehicle_location}
											/>
										);
									})}
									<div className="flex justify-center">
										<button
											className="py-2 px-8 mt-4 rounded-[7px] border-black border-[1px] flex "
											onClick={() => {
												navigate("/addfleet/addfleetcar");
											}}
										>
											<img className="mr-2" src={add_icon} alt="" />
											Add Car
										</button>
									</div>
								</div>
							)}
						</div>
					) : (
						<div className=" w-[250px] mx-auto mt-20">
							<img
								className="w-[165px] h-[130px] mx-auto"
								src={loadingGif}
								alt=""
							/>
							<div className=" whitespace-normal text-xs text-gray-500 text-center">
								{loading}
							</div>
						</div>
					)}
					{ (carlist.length >0 || driverlist.length>0) &&
					<div className="flex flex-col items-center mx-auto mt-16">
						<button
							className="border rounded-md px-3 py-2 border-black"
							onClick={()=>setDoneModal(!doneModal)}
						>
							Confirm Registration
						</button>
					</div>
					}
				</div>
      </div>
      {
        doneModal && <Done/>
      }
      
		</>
	);
}

function DriverCard({ name, mobile }) {
	return (
		<>
			<div className="min-h-24 border-[1px] rounded-[7px] mb-2">
				<div className="flex justify-around py-2 bg-[#f0f0f0]">
					<p className="w-full text-center">Name</p>
					<p className="w-full text-left">Mobile No</p>
				</div>
				<div className="flex p-2 px-4 ">
					<div className="flex w-1/2 ">
						{/* <img className=' w-[25%] aspect-[1] mr-4 rounded-[7px] border-2 border-solid border-[gray]' src={add_icon} alt="" /> */}
						<p className="break-words w-full">{name}</p>
					</div>
					<p className="text-left w-1/2">{mobile}</p>
				</div>
			</div>
		</>
	);
}
function CarCard({ car_number, model, location }) {
	console.log({ car_number, model, location });
	return (
		<>
			<div className="min-h-24 border-[1px] rounded-[7px] mb-2">
				<div className="flex justify-around py-2 px-4 bg-[#f0f0f0]">
					<p className="w-full text-left">Car Number</p>
					<p className="w-full text-left">Model</p>
				</div>
				<div className="flex py-4 items-center px-4">
					<div className="flex w-1/2 items-center">
						<div className="">
							<p className="break-words text-left w-full">{car_number}</p>
							<p className=" text-[#ffcd02] break-words text-left w-full">
								{location}
							</p>
						</div>
					</div>
					<p className="text-left w-1/2 break-words">{model}</p>
				</div>
			</div>
		</>
	);
}
