import carLoadingGif from "../assets/carLoading.gif";

export const LoaderScreen = ({ message }) => (
  <div className="w-[250px] mx-auto mt-20">
    <img className="w-[165px] h-[130px] mx-auto" src={carLoadingGif} alt="Moving Car Icon" />
    <div className=" whitespace-normal text-xs text-gray-500 text-center">
      {message}
    </div>
  </div>
);
